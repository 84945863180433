import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";


export const Strip = () => {
  return (
    
      <div className="Strip">

        <div className="tag">
        <h2>Email: <a style={{textDecoration:"none", color:"white"}} href="mailto:contact@cntlogistic.com">contact@cntlogistic.com
</a></h2></div>

      </div>
  
  );
};
