import React from "react";
import { FaCheck } from "react-icons/fa";
import pics from "../assets/img/4486615.jpg"

export const Services = () => {
  return (
    <section className="Services">
      

      <div className="Moving">
        <h1 style={{fontSize:"34px"}}>Our Mission</h1>
        <p>

Our mission is to provide the best possible auto transportation services to our customers.
 We understand that your vehicle is important to you, and we treat every vehicle with respect,
  care, and attention to detail. Our team is committed to delivering your vehicle to its destination
   safely and efficiently, while ensuring that it arrives in the same condition as when it was picked up.
    We strive to provide exceptional customer
 service at every step of the way, and our ultimate goal is to exceed your expectations and earn your trust.</p>
        
        
      </div>

      <div className="ServicesPics">
        <img src={pics} alt="tasveer" width={1200} />
      </div>
    </section>
  );
};
