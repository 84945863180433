import React from "react";
import { GiSmartphone } from 'react-icons/gi';
import { FaPhoneAlt } from 'react-icons/fa';
import logo from "../assets/img/logous.png";

export const NavBar = () => {

  return (
    <div className="Navbar">
      <div className="NavbarContainer">
        <div className="logo">
          <a href="/">
          <img src={logo} alt="tasveer" /></a>
        </div>
        <div className="order">
          <div className="phone">
            
            
          </div>
          <div className="button">
            <button>
              <div> <FaPhoneAlt style={{marginRight:"5px"}} size={14} /><a style={{textDecoration:"none"}} href="tel:18444602392">(844) 4602392</a></div>
            </button>

          </div>

        </div>
      </div>
    </div>
  );
};
