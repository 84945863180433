import './App.css';
import { Banner } from './components/Banner';
import { Footer } from './components/Footer';
import { Intro } from './components/Intro';
import { NavBar } from './components/NavBar';
import { Plan } from './components/Plan';
import { Services } from './components/Services';
import { Specialize } from './components/Specialize';
import { Strip } from './components/Strip';
import { Testimon } from './components/Testimon';
import { ContactUs } from './components/contact';

function App() {
  return (
    <div className="App">
      <Strip />
      <NavBar />
      <Intro />
      <Banner />
      <ContactUs />
      
      <Services />
      <Plan />
      <Testimon />
      <Footer />
      
    </div>
  );
}

export default App;
