import React from "react";
import boy from "../assets/img/boy.jpg"
import { FaCheck } from "react-icons/fa";

export const ContactUs = () => {

  return (
    <section className="Contact">
      <div className="oneStrip">
        <div className="special">
          <h2>Want to Ship your vehicle with CNT Logistic?</h2>
        </div>
        <div className="num">
          <button>Get a Quote</button>
        </div>
      </div>
      <div className="ContactContainer">
        <div className="boy">
          <img src={boy} alt="tasveer" width={500} height={500} />

        </div>
        <div className="contactText" id="choose"> 
        <p style={{background:"linear-gradient(to right, red, rgba(255, 0, 0, 0.156))", color:"white", fontWeight:"bold", padding:"5px"}}>RELIABLE FAST PROFESSIONAL</p>
        <h2>Why Choose CNT Logistic?</h2>
          
          <p><FaCheck style={{color:"purple", marginRight:"5px"}} />Reliability and Professionalism</p>
          <p><FaCheck style={{color:"purple", marginRight:"5px"}} />Nationwide Coverage</p>
          <p><FaCheck style={{color:"purple", marginRight:"5px"}} />Variety of Services</p>
          <p><FaCheck style={{color:"purple", marginRight:"5px"}} />Competitive Pricing</p>
          <p><FaCheck style={{color:"purple", marginRight:"5px"}} />Excellent customer service</p>
          <p><FaCheck style={{color:"purple", marginRight:"5px"}} />Experienced team</p>

        </div>

      </div>
    </section>
  );
};
