import React from 'react';
import { FaMailchimp } from 'react-icons/fa';

export const Footer = () => {
  
  return(
      <section className="footer-container">

        

        <div className='footerBox'>
          <div className='about'>
<h2>About us</h2>
            <p>At CNT Logistics, our approach to transportation and shipping is centered on
               providing the best possible service to our customers. We understand that every business has 
               unique shipping needs,
               and we work closely with our clients to understand those needs and develop customized solutions to meet them.</p>

          </div>
          
          <div className='quicklinks'>
            <h2>Quicklinks</h2>
            <a style={{textDecoration:"none", color:"white"}} href="#home">
            <p>. Home</p></a>
            <a style={{textDecoration:"none", color:"white"}}  href="#services">
            <p>. Our Services</p></a>
            <a style={{textDecoration:"none", color:"white"}}  href="#choose">
            <p>. Why Choose us?</p></a>
            <a style={{textDecoration:"none", color:"white"}}  href="#reviews">
            <p>. Reviews</p></a>
            <a style={{textDecoration:"none", color:"white"}}  href="https://cntlogistic.com/privacy-policy/">
            <p>. Privacy Policy</p></a>
            
            
            
            
            


          </div>

          <div className='numa'>
            <h2>Call now to schedule your Shipment<br/>
             
            <br /> <a style={{textDecoration:"none", color:"white"}} href="tel:18444602392">+1 (844) 4602392</a></h2>
            <p style={{color:"orange"}}><FaMailchimp /> <a style={{textDecoration:"none", color:"orange", fontSize:"16px" }} className="email" href="mailto:contact@cntlogistic.com">
            Email Now
          </a> </p>
          <p><b>Address:</b> 245 Colonial Ave, Unit 1A, Waterbury, CT 06704</p>

          </div>
          </div>
         
          <div>

            <hr style={{width:"70%"}} />

            <p>CNT Logistics 2023 - All Rights Reserved</p>

          </div>

        </section>);
      

 };
