import React from "react";
import { FaBoxOpen, FaCarAlt, FaCarSide, FaLock, FaMobile, FaMotorcycle, FaTools } from "react-icons/fa";
import { IoMdBoat } from "react-icons/io";


export const Banner = () => {
  return (
    <section className="Banner" id="services">
      <div className="BannerContainer">
        <h1 style={{fontSize:"40px"}} >Our Services</h1>
        <p>At CNT Logistic, we offer a wide range of car transport services to meet your needs.<br />
          Whether you need to transport a single vehicle or multiple vehicles,
           we have the expertise<br /> and experience to get the job done. Our services include:
        </p>
        <br /><br />

        <div className="PlansContainer">
          
          <div className="PlansItems">
            <div className="icon">
            <FaBoxOpen /></div>
            <h2>Open Auto Transport</h2>
         </div>

         <div className="PlansItems">
         <div className="icon">
            <FaLock /></div>
            <h2>Enclosed Transport</h2>
         </div>

         <div className="PlansItems">
         <div className="icon">
         <FaCarSide /> </div>
            <h2>Classic Car Transport</h2>
         </div>
         
         <div className="PlansItems">
         <div className="icon">
         <FaCarAlt />  </div>
            <h2>Exotic Car Transport</h2>
         </div>

         <div className="PlansItems">
         <div className="icon">
            <FaMotorcycle /></div>
            <h2>Motorcycle Transport</h2>
         </div>

         <div className="PlansItems">
         <div className="icon">
            <IoMdBoat /></div>
            <h2>Boat Transport</h2>
         </div> 

         <div className="PlansItems">
         <div className="icon">
            <FaTools /></div>
            <h2>Inoperable vehicle transport</h2>
         </div>

        </div>

      </div>


    </section>
  );
};
