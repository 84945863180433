
import { FaStar } from "react-icons/fa";
import img1 from "../assets/img/reviews1.png";


export const Testimon = () => {
  return (
    <section className="Testimon" id="reviews">

      <div className="clients">
        
        <p style={{color:"midnightblue", fontWeight:"bold"}}>Reviews & Testimonials</p>
        <h1>Our Happy Clients</h1>
        <p>"Attracting customers with our impeccable 5-star Trustpilot reviews!"</p>
        
<div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="64235bff9375acebcb864f3f" data-style-height="24px" data-style-width="100%" data-theme="light" data-min-review-count="10" data-style-alignment="center">
  <a href="https://www.trustpilot.com/review/cntlogistic.com" target="_blank" rel="noopener">Trustpilot</a>
</div>

      </div>
      <br/><br/>

      <div className="TestimonContainer">



      <div className="elements">

        <img src={img1} alt="tasveer" />
        
        <br />
        <br />
        Great experience, super friendly, easy process.Representative was great and really made the process easy. 
        I didn’t feel pressured or obligated. I’ll definitely be back.
        <br /><br />
        <FaStar color="midnightblue" fontSize="1em" />
        <FaStar color="midnightblue" fontSize="1em" />
        <FaStar color="midnightblue" fontSize="1em" />
        <FaStar color="midnightblue" fontSize="1em" />
        <FaStar color="midnightblue" fontSize="1em" />
        <br />
        <br />
        
        <h5>Jacob Byrne</h5>
        <p>Given on Transport Reviews</p>
      </div>

      
      </div>

      <div className="twoStrip">
        <div className="special">
          <p>Check availability in your area</p>
          <h2>Connect with our Specialist Now!</h2>
        </div>
        <div className="num">
          <p>Call Now!</p>
          <h1 style={{color:"midnightblue"}}><a style={{textDecoration:"none", color:"midnightblue"}} href="tel:18444602392">+1 (844) 4602392</a></h1>
        </div>
      </div>

    </section>
  );
};
