import React from "react";

export const Intro = () => {
  const iframeSrc = "https://leadform.batscrm.com/?ref=0e82b8e9-f014-4acd-9609-f0580ab368b4&buttoncolor=fff&buttonbgcolor=FF0000&formbg=191970&formcolor=FFFFFF";

  // Event listener to adjust the iframe height
  const receiveSize = (e) => {
    if (e.origin === "https://leadform.batscrm.com/") {
      if (e.data.method === "adjust_height") {
        const newHeight = e.data.height + 2;
        document.getElementById("bats-lead-form").style.height = `${newHeight}px`;
      }
    }
  };

  // Attach the event listener when the component mounts
  React.useEffect(() => {
    window.addEventListener("message", receiveSize, false);
    
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", receiveSize);
    };
  }, []);

  return (
    <section className="intro" id="home">
      <div className="introContainer">
        <div className="text">
          <p style={{color:"red"}}>Over a World Away</p>
          <h1>CNT LOGISTICS</h1>
          <p>Efficient car shipping with unwavering transparency. Enjoy the confidence of knowing exactly 
            where your vehicle is at all times with our real-time GPS tracking technology. We make car shipping stress-free.</p>
          
          <button><a style={{textDecoration:"none", color:"white"}} href="tel:18444602392">CALL Now</a></button>
        </div>


        <div className="calender">

        <iframe
      id="bats-lead-form"
      src={iframeSrc}
      frameBorder="0"
      style={{ minHeight: "500px", width: "318px" }}
    ></iframe>

        </div>

      </div>

    </section>
  );
};
