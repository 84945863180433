import React, { useState } from "react";
import "./faqs.css"; // Be sure to create the corresponding CSS file
import { FaQuora } from "react-icons/fa";

export const Plan = () => {
  const faqs = [
    {
      question: <h3><FaQuora color="midnightblue" size={20} /> : How much does it cost to ship a car?</h3> ,
      answer:
        "The cost of shipping a car actually depends on from where it will be picked up and urgency of delivery. This is similar to how you select a carrier when mailing packages with different priorities. Calculate your car shipping quote instantly or talk to our shipping agents to get a rate for your car shipment.",
    },
    {
      question: <h3><FaQuora color="midnightblue" size={20}  /> : How will my car be picked up and delivered?</h3>,
      answer:
        "The location given in the pickup address will be used by the driver as long as the location/road can fit the carrier's vehicle for loading. Vehicle will be picked up on the agreed time and will be transported to the given destination in the agreed time frame. The driver will contact you or your representative to accept the delivery.",
    },
    {
      question: <h3><FaQuora color="midnightblue" size={20}  /> : How do I make payment for the Car Shipment?</h3>,
      answer:
        "We accept all major debit and credit cards for the payment. A small reservation deposit is taken to confirm your booking before picking up the vehicle, and the balance due is paid on delivery of the vehicle in cash, bank/certified check, or postal money order. A full pre-payment can also be made through the credit card as an option.",
    },
    {
      question: <h3><FaQuora color="midnightblue" size={20}  /> : What Vehicles do you ship?</h3>,
      answer:"We can ship all types of vehicles such as sedans, SUVs, pickup trucks, motorcycles and minivans. If you are not sure please feel free to give us a call.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="faq-container" id="faqs">
      <p style={{color:"midnightblue", fontWeight:"bold"}}>Your FAQ's</p>
      <h1>Frequently Asked Questions</h1>
      <p>In Search of Answers? Look No Further – Our FAQ section is here
         to address your concerns and guide you through the car shipping process</p>
         <br />
      
      <ul className="faq-list">
        {faqs.map((faq, index) => (
          <li key={index} className="faq-item">
            <button
              className="faq-question"
              onClick={() => handleAccordionClick(index)}
            >
              {faq.question}
            </button>
            {activeIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

